import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from '../../context/AuthContext';

import BoxCoverPlayerDetailsComponent from '../../components/BoxCoverPlayerDetailsComponent/BoxCoverPlayerDetailsComponent';
import ModalContainer from '../../components/ModalContainer/ModalContainer.js';
import SignIn from '../../components/SignIn/SignIn.js';
import Notifications from '../../components/Notifications/Notifications';

import './StreamTest.scss';

/*
This component is used for diagnostics outside of the age verification process, so we can determine if the WL is working or not
 */

const StreamTest = () => {
  const { t } = useTranslation();
  const { userLoggedIn, logout } = useAuthContext();

  useEffect(() => {
    const onLinkClick = (e) => {
      const elem = e.target || {};
      if (elem.tagName?.toLowerCase() === 'a') {
        window.location.reload();
      }
    }

    document.addEventListener('click', onLinkClick);

    return () => document.removeEventListener('click', onLinkClick);

  }, []);

  const renderUserActions = () => {
    return (
      <div className="AccountContainer">
        {userLoggedIn ? (
          <span onClick={() => {
            logout(() => window.location.reload());
          }}
            style={{ cursor: 'pointer', textTransform: 'uppercase' }}>{t('MainDashboardHeader.signOut')}</span>
        ) : (
          <ModalContainer
            className='SignInModal'
            buttonText='Login'
            showArrow
          >
            <SignIn alternateSupportLink hideForgotPsw hideJoinLink />
          </ModalContainer>
        )}
      </div>
    );
  };

  return (
    <div className="StreamTest">
      {renderUserActions()}
      <BoxCoverPlayerDetailsComponent movieId="161057" omitAds omitPreviewsCount />
      <Notifications />
    </div>
  );
};

StreamTest.displayName = 'StreamTest';

export default StreamTest;

import React, { useRef } from 'react';

import SlickSlider from '../SlickSlider/SlickSlider';

import useBanners from '../../hook/useBanners';
import useInViewport from '../../hook/useInViewport';

import './BannerSetView.scss';

const BannerSetViewFunc = (props) => {
  const { bannerName, onClick, width = window.innerWidth, hideLoadingSkeleton = false } = props;

  const containerRef = useRef(null);
  const isVisible = useInViewport(containerRef);
  const { data, info, loading } = useBanners(bannerName, isVisible);

  const getRandomBanner = () => {
    const { display_single_banner, randomize_on_refresh } = info || {};
    let banners = data;

    const shuffle = (array) => array.sort(() => Math.random() - 0.5);

    if (randomize_on_refresh) {
      banners = shuffle(data);
    }

    if (display_single_banner) {
      banners = [data[0]] || [];
    }

    return banners;
  };

  return (
    <div className="BannerSetView" ref={containerRef} data-info={`BannersSet-${info.id} (${info.name})`}>
      <SlickSlider
        slides={{ data: getRandomBanner(), info, loading }}
        onClick={onClick}
        bannerWidth={width}
        hideLoadingSkeleton={hideLoadingSkeleton}
      />
    </div>
  );
};

BannerSetViewFunc.displayName = 'BannerSetViewFunc';

export default BannerSetViewFunc;

import React from 'react';

import SinglePlayer from '../../../../components/SinglePlayer/SinglePlayer';

export default function FreeRideOrDieBonusBehindScenes() {
  return (
    <>
      <SinglePlayer
        src='https://d34kds0xkdmtq8.cloudfront.net/RSS190_SL.mp4'
        poster='https://spotlight.nakedcdn.com/nakedsword/img/free/rideordie/videoposterimage-rideordiebehindscenes.jpg'
      />

      <h4 style={{
        fontSize: '120%',
        margin: '1em 0'
      }}>
        RIDE OR DIE: BEHIND BARS :: BEHIND BARS
      </h4>

      <p>
        Go behind the cameras, and experience 'RIDE OR DIE' from a whole new perspective! Raw and revealing
        footage gives viewers an exclusive opportunity to learn what goes into creating a hardcore
        mega-production.
      </p>
    </>
  )
};
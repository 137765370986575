import React from 'react';

import './AlphabeticNavigation.scss';

export default function AlphabeticNavigation(props) {
  const { data = [], onLetterChange } = props;

  const onLetterClick = (value) => {
    if (onLetterChange) {
      onLetterChange(value);
    }
  };

  const renderLetters = () => {
    if (!Array.isArray(data)) return null;

    return data.map(item => {
      const name = item?.name || '';
      return <span className='Letter' key={name} onClick={() => onLetterClick(name)}>{name}</span>
    });
  };

  return <div className='AlphabeticNavigation'>{renderLetters()}</div>;
}
import React, { useState } from 'react';

import MoviesScenesGrid from '../../../../components/MoviesScenesGrid/MoviesScenesGrid';
import PageIndicator from '../../../../components/PageIndicator/PageIndicator';
import ImageLoaderComponent from '../../../../components/ImageLoaderComponent/ImageLoaderComponent';
import ImagesLightBox from '../../../../components/ImagesLighbox/ImagesLighbox';

import { getStarDetailsById } from '../../../../services/stars/stars.service';
import { clearSpecialCharactersReplacements } from '../../../../services/util-service/util.service';
import { navigationOptions } from '../../../../constants/navigation';
import { getImageByWidth } from '../../../../services/images-service/images.service';
import useMoviesScenesData from '../../../../hook/useMoviesScenesData';
import { handleGallery } from '../../../../services/gallery-service/gallery.service';

import './StarDetails.scss';

const StarDetails = (props) => {
  const { starId, starName: propsStarName, location = {} } = props;

  const {
    videos,
    pagination: { page, last_page },
    name = propsStarName,
    biography,
    gallery = [],
    header_images = [],
    sort,
    type,
    combo,
    loading,
    error,
    onPageChange,
    onTypeChange,
    onSortByClick,
  } = useMoviesScenesData({
    location,
    hookParams: { stars_id: starId },
    hookDetailsRequest: getStarDetailsById,
    hookDetailsParams: {
      route: starId,
      star_images_sort_order: 'DESC',
      star_images_custom_only: 1,
    },
  });

  const [state, setState] = useState({ fullScreen: false });
  const { fullScreen } = state;

  const onTypeChangeWithPhotos = (val) => {
    onTypeChange(val, () => {
      setState((prevState) => ({ ...prevState, fullScreen: true }));
    });
  };

  const renderImage = () => {
    if (header_images?.length === 0) return null;
    const { url } = getImageByWidth(header_images);
    return <ImageLoaderComponent className="StarHeaderImage" url={url} />;
  };

  const renderPagination = () => {
    if (page === 1 && last_page === 1) return null;
    if (error) return null;
    return (
      <PageIndicator
        page={page}
        pageCount={last_page}
        onPageChange={onPageChange}
        scrollIntoView=".ShowMeSortByNavigation"
      />
    );
  };

  const renderImageFullScreen = () => {
    if (!fullScreen || gallery?.length === 0) return null;
    const galleryImages = handleGallery(gallery, 1) || [];

    return (
      <ImagesLightBox
        images={galleryImages}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            fullScreen: !prevState.fullScreen,
          }))
        }
      />
    );
  };

  return (
    <div className="StarDetails">
      {renderImage()}

      <MoviesScenesGrid
        data={videos}
        loading={loading}
        type={type}
        sort={sort}
        onShowMeClick={onTypeChangeWithPhotos}
        onSortByClick={onSortByClick}
        error={error}
        hasGalleryImages={gallery?.length !== 0}
        title={clearSpecialCharactersReplacements(name)}
        subTitle={type === navigationOptions.scenes ? 'Most recent scenes' : 'Most recent movies'}
        description={biography}
        combo={combo}
      />

      {renderPagination()}
      {renderImageFullScreen()}
    </div>
  );
};

StarDetails.displayName = 'StarDetails';

export default StarDetails;

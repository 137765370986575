import axios from 'axios';
import { isUserLoggedIn } from '../token-service/token.service';
import { getNatsData } from '../nats-service/nats.service';

const urls = {
  bannersSet: '/frontend/banners_set',
  bannerClick: '/frontend/banners/click',
};

export const bannerSets = {
  benefits: {
    affiliate: 73,
    defaultSet: 71,
    member: 72,
  },
  defaultOriginals: {
    affiliate: 120,
    defaultSet: 120,
    member: 120,
  },
  featuredReleases: {
    affiliate: 78,
    defaultSet: 64,
    member: 65,
  },
  featuredReleasesLeft: {
    affiliate: 118,
    defaultSet: 118,
    member: 118,
  },
  featuredReleasesRight: {
    affiliate: 119,
    defaultSet: 119,
    member: 119,
  },
  main: {
    affiliate: 77,
    defaultSet: 12,
    member: 13,
  },
  // originalsHeading: {
  // 	affiliate: "",
  // 	defaultSet: 67,
  // 	member: ""
  // },
  originalsMiddle: {
    affiliate: '',
    defaultSet: 68,
    member: '',
  },
  playlists: {
    affiliate: '',
    defaultSet: 60,
    member: '',
  },
  playlistsHeader: {
    affiliate: '',
    defaultSet: 66,
    member: '',
  },
  stars: {
    affiliate: '',
    defaultSet: 61,
    member: '',
  },
  studios: {
    affiliate: '',
    defaultSet: 63,
    member: '',
  },
  themes: {
    affiliate: '',
    defaultSet: 62,
    member: '',
  },
  tour: {
    affiliate: 79,
    defaultSet: 69,
    member: 70,
  },
  promo: {
    affiliate: 77,
    defaultSet: 67,
    member: 59,
  },
  memberUpsellBottom: {
    affiliate: '',
    defaultSet: 80,
    member: '',
  },
  //Not sure what this is in here for - always set to incorrect value of 57 initially
  promoNonMember: {
    affiliate: '',
    defaultSet: 67,
    member: '',
  },
  //this one as well - initally set to 58
  nonMemberPromoAffiliate: {
    affiliate: '',
    defaultSet: 77,
    member: '',
  },
  exit: {
    affiliate: '',
    defaultSet: 81,
    member: '',
  },
  weekends: {
    affiliate: '',
    defaultSet: 122,
    member: 123,
    typein: '',
  },
  whatMembershipBringsYou: {
    affiliate: '',
    defaultSet: 125,
    member: '',
  },
};

export const bannerRankings = [
  {
    key: 'member',
    rankerFn: isUserLoggedIn,
  },
];

export const getBannerSet = (bannerSetId, page) => {
  const { natsCode } = getNatsData();

  return axios.get(`${urls.bannersSet}/feed`, {
    params: {
      id: bannerSetId,
      page,
      nats_code: natsCode,
    },
    withCredentials: true,
  });
};

export const getBenefitsBannerSet = (page) => getUserBannerSet('benefits', page);

export const getPlaylistsBannerSet = (page) => {
  return getUserBannerSet('playlists', page);
};

export const getUserStateBannerSetId = (setName) => {
  const keys = bannerSets[setName];
  let setId;
  if (Object.keys(keys).length > 1) {
    // More banner sets exist
    let item = 0;
    const itemsCount = bannerRankings.length;
    let currentRank;
    let isMatch;
    while (!setId && item < itemsCount) {
      currentRank = bannerRankings[item];
      isMatch = currentRank.rankerFn();
      if (keys[currentRank.key] && isMatch) {
        setId = keys[currentRank.key];
      }
      item++;
    }
    if (!setId) {
      setId = keys.defaultSet;
    }
  } else {
    setId = keys.defaultSet;
  }

  return setId;
};

export const getUserBannerSet = (setName, page) => {
  return getBannerSet(getUserStateBannerSetId(setName), page);
}; 
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';

import { ROUTES } from '../../services/navigation/navigation.routes';
import { useWLconfigContext } from '../../context/WLConfigContext';

import './MemberPWReminder.scss';

export default function MemberPWReminder({ alternateSupportLink }) {
  const [state, setState] = useState({ email: '' });
  const propertyId = process.env.REACT_APP_PROPERTY_ID;
  const { variables: { REACT_APP_WEBMASTERS_URL } = {} } = useWLconfigContext();
  const { t } = useTranslation();

  const onSupportClick = () => {
    if (alternateSupportLink) {
      navigate(alternateSupportLink);
    } else {
      navigate(`/${ROUTES.help}`);
    }
  };

  const onChange = (key, event) => {
    const { value } = event.target;
    setState(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <div className="MemberPWReminder">
      <span>{t('MemberPWReminder.subTitle')}</span><br />
      <span>{t('MemberPWReminder.pleaseContact')}</span>{' '}
      <span className="SupportLink" onClick={onSupportClick}>{t('MemberPWReminder.support')}.</span>

      <form action={`${REACT_APP_WEBMASTERS_URL}/signup/password.php`} method="GET">
        <input type="hidden" name="siteid" value={propertyId} />
        <input type="hidden" name="submit" value="1" />
        <div className="InputWrapper d-flex align-center">
          <i className="fas fa-user" />
          <input
            value={state.email}
            className="SignInInput"
            name="email"
            type="email"
            placeholder={t('MemberPWReminder.loginEmail')}
            onChange={onChange.bind(this, 'email')}
          />
        </div>

        <button type="submit" disabled={state.email.length === 0 ? true : false} className='SignInButton'>
          {t('MemberPWReminder.sendMyInfo')}
        </button>
      </form>
    </div>
  );
} 
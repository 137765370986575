import React, { useState, useEffect, useRef } from 'react';
import Arrow from '../Arrow/Arrow';

import './DropdownSelect.scss';

export default function DropdownSelect(props) {
  const {
    options,
    onChangeSearchSection,
    clearSearchValue,
    selectedDropdownValue
  } = props;

  const [state, setState] = useState(false);
  const dropdownSelectRef = useRef();

  useEffect(() => {
    const onClickOutsideCloseDropdown = (event) => {
      if (dropdownSelectRef && !dropdownSelectRef.current.contains(event.target)) {
        if (state) setState(false);
      }
    };

    document.addEventListener('mousedown', onClickOutsideCloseDropdown);

    return () => {
      document.removeEventListener('mousedown', onClickOutsideCloseDropdown);
    }
  }, [state]);

  const onDropdownClick = () => {
    setState(!state);
    if (clearSearchValue) {
      clearSearchValue();
    }
  };

  const renderOption = (option, index) => {
    const { name } = option;

    return (
      <li key={name + '-' + index} className="Option" onClick={() => onChangeSearchSection(option)}>
        <span>{name}</span>
      </li>
    );
  };

  return (
    <div
      ref={dropdownSelectRef}
      className={`DropdownSelect cursor-pointer ${state ? 'isOpen' : ''}`}
      onClick={onDropdownClick}
    >
      <span className="SelectedTerm">{selectedDropdownValue?.name}</span>
      <Arrow show={state} />
      <ul className="DropdownSelectContent">
        {options.map((option, index) => renderOption(option, index))}
      </ul>
    </div>
  );
}
import React, { createContext, useContext, useState, useEffect, useRef } from 'react';

const NotificationContext = createContext();

export const NOTIFICATION_TYPES = {
  info: 'info',
  error: 'error',
};

const NotificationProvider = ({ children }) => {
  const [notificationState, setNotificationState] = useState({
    title: '',
    message: '',
    duration: 3000,
    type: NOTIFICATION_TYPES.info
  });

  const timeoutIdRef = useRef();

  const displayNotification = ({
    title = '',
    message = '',
    duration = 3000,
    type = NOTIFICATION_TYPES.info
  }) => {
    const iconType = type === NOTIFICATION_TYPES.info ? NOTIFICATION_TYPES.info : NOTIFICATION_TYPES.error;
    setNotificationState(prevState => ({ ...prevState, title, message, duration, type: iconType }));
  };

  const resetNotificationState = () => {
    setNotificationState({ title: '', message: '', duration: 3000, type: NOTIFICATION_TYPES.info });
  };

  const hideNotification = () => {
    resetNotificationState();
    clearTimeout(timeoutIdRef.current);
  };

  useEffect(() => {
    timeoutIdRef.current = setTimeout(() => {
      resetNotificationState();
    }, notificationState.duration);

    return () => {
      clearTimeout(timeoutIdRef.current);
    }

  }, [notificationState.title, notificationState.message, notificationState.duration]);

  return (
    <NotificationContext.Provider value={{ displayNotification, hideNotification, ...notificationState }}>
      {children}
    </NotificationContext.Provider>
  );
};

const useNotificationsContext = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('NotificationContext must be used within a NotificationProvider!');
  }
  return context;
};

export { NotificationProvider, useNotificationsContext };


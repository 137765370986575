
import { useEffect, useRef } from 'react';

import './ChatMessage.scss';

export default function ChatMessage(props) {
  const {
    inputValue,
    onInputChange,
    goBackToList,
    selectedBot,
    onSendMessage,
    messages,
    feedback,
    handleFeedback
  } = props;

  const msgContainerRef = useRef();

  useEffect(() => {
    // scroll to bottom when receive a new message
    const list = msgContainerRef.current;
    if (list) {
      list.scrollTop = list.scrollHeight;
    }
  }, [messages.length]);

  const handleInputChange = (e) => {
    const val = e.target.value;
    if (val.charAt(0) === ' ') return;

    if (onInputChange) {
      const regex = /[^0-9A-Za-z\s,.!?'":;-]+/;
      if (regex.test(val)) return;
      onInputChange(val);
    }
  };

  // send message to chatbot
  const onSendMsg = () => {
    if (onSendMessage) {
      onSendMessage();
    }
  };

  // on press Enter send message to chatbot
  const handleInputKeyUp = (event) => {
    const { key } = event;

    if (key === 'Enter' && inputValue) {
      onSendMsg();
    }
  };

  // go back to full list
  const goBack = () => {
    if (goBackToList) {
      goBackToList(null);
    }
  };

  const renderMesages = () => {
    if (!Array.isArray(messages)) return null;

    return (
      <ul>
        {messages.map((item, index) => {
          const { role, content } = item || {};
          const isUser = role === 'user';

          return (
            <li key={index} className={isUser ? 'UserMsg' : 'BotMsg'}>
              {!isUser && (
                <img
                  className='ChatMsgImg'
                  src={selectedBot.image}
                  alt={selectedBot.name}
                  width={25}
                  height={25}
                />
              )}
              <div><span className='MsgText'>{content}</span></div>
            </li>
          )
        })}
      </ul>
    )
  };

  const renderFeedbackView = () => {
    if (!feedback.send) return null;

    return (
      <>
        <div className='FeedbackTitle'>Share your feedback</div>
        <div>Thanks for sending us your ideas, issues or compliments. <br /> We appreciate your time and value your feedback!</div>

        <div className='FeedbackResponse'>
          {feedback.loading && <i className='fa fa-spinner fa-spin'></i>}
          {feedback.success && (
            <>
              <i className='fa fa-check'></i>
              <div>Thank you!</div>
              <div>Your feedback has been sent.</div>
              <span onClick={goBack} className='BackToFullListBtn cursor-pointer'>Go back to full list</span>
            </>
          )}
        </div>
      </>
    )
  };

  return (
    <div className='ChatMessage d-flex'>
      <div className='ChatMessageHeader d-flex align-center'>
        <span
          className='ChatMsgBackBtn cursor-pointer fa fa-chevron-left'
          onClick={goBack}
          title='Go Back'
        ></span>
        <div className='d-flex align-center'>
          <img className='ChatMsgImg' src={selectedBot.image} alt={selectedBot.name} width={38} height={38} />
          <span>{selectedBot.name}</span>
        </div>
      </div>

      <div className='ChatMessageContent' ref={msgContainerRef}>
        {!feedback.send && renderMesages()}
        {renderFeedbackView()}
      </div>

      <div className='ChatMessageInputContainer'>
        <input
          className='ChatMessageInput'
          type='text'
          value={inputValue}
          onChange={handleInputChange}
          onKeyUp={handleInputKeyUp}
          placeholder={`${feedback.send ? 'Send feedback...' : 'Write a message...'}`}
        />
        <span
          className='ChatMsgSendBtn cursor-pointer fa fa-paper-plane'
          onClick={onSendMsg}
          title='Send'
        ></span>
      </div>

      {!feedback.send && (
        <div className='ChatFeedbackBtn'>
          <span
            onClick={() => {
              if (handleFeedback) {
                handleFeedback();
              }
            }}
            className='cursor-pointer'
            title='Send us your feedback about this chatbot character!'>Send us your Feedback!</span>
        </div>
      )}
    </div>
  )
};
import './ChatList.scss';

export default function ChatList(props) {
  const { items, onListItemClick } = props;

  if (!Array.isArray(items)) return null;

  const handleListItemClick = (id) => {
    if (onListItemClick) {
      onListItemClick(id);
    }
  };

  return (
    <div className='ChatList'>
      <div className='ChatListHeader'>Choose chatbot character to chat with</div>
      <ul>
        {items.map(item => {
          const { id, gender = '', image = '', name = '', orientation = '', public_info = '' } = item || {};

          return (
            <li className='ChatListItem' onClick={() => handleListItemClick(id)} key={id}>
              <div className='ChatListImageContainer'>
                <img className='ChatListImage' src={image} alt={name} width={80} height={80} />
              </div>

              <div>
                <div className='ChatListTitle d-flex align-center'>
                  <span>{name}</span>
                  <span>{gender}, {orientation}</span>
                </div>
                <div className='ChatListInfo' title={public_info}>{public_info}</div>
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
};
import React from 'react';

import SinglePlayer from '../../../components/SinglePlayer/SinglePlayer';

export default function Party20th() {
  return (
    <>
      <SinglePlayer
        src='https://d34kds0xkdmtq8.cloudfront.net/FSP052_SA.mp4'
        poster='https://spotlight.nakedcdn.com/nakedsword/img/ns_play/NS20Party_16x9.jpg'
      />

      <h2>NAKEDSWORD'S 20TH ANNIVERSARY CELEBRATION</h2>
      <p>
        Dubbed "The Netflix of Gay Porn" by the SF Chronicle, NakedSword invites you to a celebration twenty years
        in the making! Join hosts Tim Valenti and Sister Roma for a virtual show jam-packed with celebrity guests,
        hilarious clips, stunning performances, porn swag giveaways and more! Go behind-the-scenes with LIVE
        guests including mr. Pam, Calvin Banks, Dakota Payne, Wesley Woods, Michael Stabile, Marc MacNamara, and
        California State Senator Scott Weiner. Plus, favorite moments from the Tim & Roma Show, Clips from
        NakedSword Originals most talked-about movies, a NakedSword Family Reunion Group Chat and more!
        <br />
        <br />
        Featuring appearances by Bruce Beckham, Theo Ford, Jackie Beat, Coco Peru, Shangela, Bruce Vilanch,
        Margaret Cho, Jake Sheers, Kathy Griffin, and more! DJ sets by superstars Chi Chi LaRue & Alam Wernik.
        Special performance by Miss Pineapple 2020 winner Gemini Dai.
        <br />
        <br />
        <img
          src="https://spotlight.nakedcdn.com/nakedsword/img/ns_play/wk-20th-guests.jpg"
          width="100%"
          height="auto"
          alt="Week 20th Guests"
        />
      </p>
    </>
  );
} 
import axios from 'axios';
import { env } from '../../env';
import { getNatsData } from '../nats-service/nats.service';

const urls = {
  ads: '/frontend/ads_config',
};

const propertyId = parseInt(env.REACT_APP_PROPERTY_ID);

export const getVideoAds = async (movieId = undefined) => {
  const { natsCode } = getNatsData();

  // Priority is the following: NatsAds, MovieAds, PropertyAds
  const adsConfigurations = {
    nats_code: natsCode,
    movies_id: movieId,
    properties_id: propertyId,
  };
  const configurationKeys = Object.keys(adsConfigurations);
  const promises = [];

  for (const key of configurationKeys) {
    const request = axios
      .get(`${urls.ads}`, {
        params: { [key]: adsConfigurations[key] },
      })
      .catch((err) => console.log(err));

    promises.push(request);
  }

  const results = await Promise.all(promises);
  const adsObj = {};

  if (results && results.length !== 0) {
    results.reduce((acc, currValue = {}) => {
      const ads = currValue.data?.data?.ads_config;
      const natsCodes = currValue.data?.data?.nats_codes || [];

      if (ads) {
        if (ads.properties) {
          const currentProperty = ads?.properties?.find((item) => item?.propertyId === propertyId) || {};

          // Scene ads
          if (currentProperty.scenes?.length !== 0) {
            Object.assign(adsObj, { sceneAds: currentProperty.scenes });
          }

          // Movie ads
          if (currentProperty.movieAds?.length !== 0) {
            Object.assign(adsObj, { movieAds: currentProperty.movieAds });
          }
        } else {
          // Nats and Properties ads
          if (natsCodes.length !== 0) {
            Object.assign(adsObj, { ads });
          } else {
            Object.assign(adsObj, { propertiesAds: ads });
          }
        }
      }

      // nats ads has priority over property and movies ads
      if (adsObj.natsAds) {
        delete adsObj.propertiesAds;
        delete adsObj.movieAds;
        delete adsObj.sceneAds;
      }

      return currValue;
    }, adsObj);
  }

  return adsObj;
};

import React from 'react';
import Loader from 'react-loader-spinner';

import './LoadingMask.scss';

export default function LoadingMask({ showLoadingMask }) {
  if (!showLoadingMask) return null;

  return (
    <div className='LoadingMask d-flex align-center justify-center'>
      <Loader type="ThreeDots" height="200" width="200" />
    </div>
  );
}

export const LoadingText = () => <div className="SuspenseLoader">Loading...</div>;

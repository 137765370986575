import React from 'react';
import { NOTIFICATION_TYPES, useNotificationsContext } from '../../context/NotificationsContext';

import './Notifications.scss';

export default function Notifications() {
  const { title, message, type, hideNotification } = useNotificationsContext();

  const icon = type === NOTIFICATION_TYPES.info ? 'fa fa-info-circle InfoIcon' : 'far fa-times-circle ErrorIcon';

  return (
    <div className={`NotificationsContainer ${title ? 'ToggleNotification' : ''}`}>
      <div className='d-flex align-center'>
        <i className={icon}></i>
        <div className='N-Content'>
          <div className='N-Title'>{title}</div>
          <div className='N-Message'>{message}</div>
        </div>
        <i onClick={hideNotification} className='fa fa-times cursor-pointer CloseIcon'></i>
      </div>
    </div>
  )
}

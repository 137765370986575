import React from 'react';
import { Link } from '@reach/router';

import SinglePlayer from '../../../components/SinglePlayer/SinglePlayer';

const data = [
  { url: "/31095/cade-maddox", name: 'Cade Maddox' },
  { url: "/121746/devin-franco", name: 'Devin Franco' },
  { url: "/132773/devin-trez", name: 'Devin Trez' },
  { url: "/99245/drew-sebastian", name: 'Drew Sebastian' },
  { url: "/134442/johnny-ford", name: 'Johnny Ford' },
  { url: "/129432/liam-knox", name: 'Liam Knox' },
  { url: "/72459/logan-stevens", name: 'Logan Stevens' },
  { url: "/127105/max-adonis", name: 'Max Adonis' },
  { url: "/90408/trenton-ducati", name: 'Trenton Ducati' },
  { url: "/99971/boomer-banks", name: 'Boomer Banks' },
  { url: "/26872/bruce-beckham", name: 'Bruce Beckham' },
  { url: "/64012/max-konnor", name: 'Max Konnor' },
  { url: "/133891/sharok", name: 'Sharok' },
  { url: "/136313/shawn-raymond", name: 'Shawn Raymond' },
  { url: "/31403/steve-cruz", name: 'Steve Cruz' },
  { url: "/127733/zario-travezz", name: 'Zario Travezz' },
  { url: "/134749/kaden-hylls", name: 'Kaden Hylls' },
  { url: "/35524/casey-everett", name: 'Casey Everett' },
  { url: "/129578/dillon-diaz", name: 'Dillon Diaz' },
  { url: "/63866/johnny-hill", name: 'Johnny Hill' },
  { url: "/131807/michael-boston", name: 'Michael Boston' },
  { url: "/127644/nick-fitt", name: 'Nick Fitt' },
  { url: "/130354/clark-davis", name: 'Clark Davis' },
];

export default function Mask4MaskPage() {
  return (
    <>
      <SinglePlayer
        src='https://d34kds0xkdmtq8.cloudfront.net/FSP045_SA.mp4'
        poster='https://spotlight.nakedcdn.com/nakedsword/img/ns_play/mask-for-masc-FSP045_16x9.jpg'
      />

      <h2>#MASK4MASK</h2>
      <p>
        It's 2020 and everyone knows that a face covering is one of the most important tools we have in slowing
        the spread of COVID-19. Here at Falcon/NakedSword we know it's more than that - we know that wearing a
        mask is sexy! To prove it, we've asked some of your favorite stars to show us their mask game - and it's
        strong! No matter what their doing or why they wear one, these guys are protecting themselves and others,
        and damn, that's sexy!
      </p>
      <p>
        We hope you're staying safe, healthy and keeping it sexy! Let's flatten the curve by wearing face
        coverings, keeping 6 feet physical distance, and staying home as much as possible. Together we can slow
        the spread of COVID-19 and flatten the curve. We did it before, we can do it again.
      </p>

      Featuring exclusive:{' '}

      {data.map((item, index) => {
        const { url, name } = item;

        return (
          <Link
            to={`/stars${url}`}
            target="_blank"
            style={{ display: 'inline-block', marginRight: '.3em' }}
            key={url}>
            {name}{index !== data.length - 1 && ', '}
          </Link>
        )
      })}

      <p>Executive Producer Tim Valenti</p>
    </>
  );
} 
import { createContext, useContext, useState, useEffect } from 'react';
import { getHtmlContent } from '../services/properties/properties.service';
import { ROUTES } from '../services/navigation/navigation.routes';

// get content from Gandolf
// Actions -> Edit HTML Content

const HtmlContentContext = createContext();

const HtmlContentProvider = ({ children }) => {
  const [state, setState] = useState({
    htmlContent: {},
    htmlContentLoading: true,
  });

  useEffect(() => {
    const pathname = window.location.pathname;
    const noRequestOnUrls = [`/${ROUTES.diag}`];
    if (noRequestOnUrls.includes(pathname)) {
      return;
    }

    getHtmlContent()
      .then((resp) => {
        const response = resp?.data?.data || {};
        if (Object.keys(response).length !== 0) {
          setState((prevState) => ({
            ...prevState,
            htmlContent: { ...response },
          }));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setState((prevState) => ({ ...prevState, htmlContentLoading: false })));
  }, []);

  return <HtmlContentContext.Provider value={{ ...state }}>{children}</HtmlContentContext.Provider>;
};

const useHtmlContentContext = () => {
  const context = useContext(HtmlContentContext);
  if (context === undefined) {
    throw new Error('HtmlContentContext must be used within a HtmlContentProvider!');
  }
  return context;
};

export { HtmlContentProvider, useHtmlContentContext };

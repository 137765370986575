import React, { useState, useEffect, useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';

import { useNotificationsContext } from '../../../context/NotificationsContext';
import {
  getAllPlaylists,
  addMovieSceneToPlaylist,
  removeMovieSceneFromPlaylist,
  createPlaylist,
  deletePlaylist,
  updatePlaylist,
} from '../../../services/playlists-service/playlists.service';
import { routes } from '../../../services/navigation/navigation.service.routes';
import { isWeb } from '../../../services/util-service/util.service';

import ImageLoadingSkeleton from '../../ImageLoadingSkeleton/ImageLoadingSkeleton';

import './PlaylistsPlayerPopup.scss';

export const PLAYLIST_ACTION = {
  create: 'create',
  update: 'update',
  delete: 'delete',
  add: 'add',
};

const PlaylistsPlayerPopup = forwardRef((props, ref) => {
  const { movieId, sceneId, create, update, callback, hideList, closePopup, selectedPlaylist = {} } = props;
  const { displayNotification } = useNotificationsContext();

  const pathname = window.location.pathname;

  const [state, setState] = useState({
    allPlaylists: [],
    filteredPlaylist: [],
    isVideoInPlaylist: [],
    playlistLoading: true,
    error: false,
    showAddNewPlaylist: false,
    inputValue: selectedPlaylist.name || '',
    searchValue: '',
    eventLoading: {
      [PLAYLIST_ACTION.create]: false,
      [PLAYLIST_ACTION.update]: false,
      [PLAYLIST_ACTION.delete]: false,
      [PLAYLIST_ACTION.add]: '',
    },
    showDeleteOverlay: false,
  });

  const {
    allPlaylists = [],
    filteredPlaylist = [],
    isVideoInPlaylist = [],
    playlistLoading,
    error,
    showAddNewPlaylist,
    inputValue,
    searchValue,
    eventLoading = {},
    showDeleteOverlay,
  } = state;

  // add/remove movie/scene from playlist
  const onAddRemoveVideoFromPlaylist = (id) => {
    setState((prevState) => ({
      ...prevState,
      eventLoading: { ...prevState.eventLoading, add: id },
    }));
    const params = {
      playlistId: id,
      movieId,
      sceneId,
    };
    const hasMovieSceneInPlaylist = isVideoInPlaylist.includes(id);

    const fn = hasMovieSceneInPlaylist ? removeMovieSceneFromPlaylist : addMovieSceneToPlaylist;
    fn({ ...params })
      .then(() => renderNotification(`${hasMovieSceneInPlaylist ? 'Remove from' : 'Add to'} playlist success!`))
      .catch((err) => {
        renderNotification('Maximum 50 videos allowed!');
        console.log(err);
      })
      .finally(() => {
        setState((prevState) => ({
          ...prevState,
          eventLoading: { ...prevState.eventLoading, add: '' },
        }));
        if (hasMovieSceneInPlaylist) {
          const updateList = isVideoInPlaylist.filter((item) => item !== id);
          setState((prevState) => ({
            ...prevState,
            isVideoInPlaylist: updateList,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            isVideoInPlaylist: [...prevState.isVideoInPlaylist, id],
          }));
        }
        callback && callback(); // close popup from parent
      });
  };

  const fetchAllPlaylists = useCallback(() => {
    getAllPlaylists()
      .then((resp = {}) => {
        const playlist = resp.data?.data || [];

        if (playlist.length !== 0) {
          setState((prevState) => ({ ...prevState, allPlaylists: playlist }));

          // check is movie/scene is already in the playlist
          const idsSet = new Set();
          playlist.forEach((item) => {
            item.contents_ids?.forEach((videos) => {
              const { movies_id, scenes_id } = videos || {};
              if (+sceneId === +scenes_id || +movieId === +movies_id) {
                idsSet.add(item.id);
                setState((prevState) => ({
                  ...prevState,
                  isVideoInPlaylist: [...idsSet],
                }));
              }
            });
          });
        } else {
          setState((prevState) => ({ ...prevState, showAddNewPlaylist: true }));
        }
      })
      .catch((err) => {
        setState((prevState) => ({ ...prevState, error: true }));
        console.log(err);
      })
      .finally(() => setState((prevState) => ({ ...prevState, playlistLoading: false })));
  }, [movieId, sceneId]);

  useEffect(() => {
    if (hideList) {
      setState((prevState) => ({ ...prevState, playlistLoading: false }));
      return;
    }

    fetchAllPlaylists();

    return () => setState({});
  }, [fetchAllPlaylists, hideList]);

  // if popup is offscreen
  useEffect(() => {
    if (isWeb()) {
      if (ref && ref.current) {
        const elem = ref.current;
        const elemOffset = elem.getBoundingClientRect();

        if (elemOffset.right > window.innerWidth) {
          elem.style.left = window.innerWidth - (elemOffset.left + elemOffset.width + 20) + 'px';
          elem.classList.add('HideAfterPseudo');
        }
        if (elemOffset.top < 0) {
          elem.style.top = '120%';
          elem.style.bottom = 'unset';
          elem.classList.add('HideAfterPseudo');
        }
      }
    }
  }, [ref]);

  const onInputChange = (e, action) => {
    const value = e.target.value || '';
    const onlyCharsAndNums = /[^0-9A-Za-z\s]+/;

    if (onlyCharsAndNums.test(value)) {
      // renderNotification('Special characters are not allowed!', 3);
      return;
    }

    if (action === 'search') {
      const updatedList = allPlaylists.filter((item) => item.name?.toLowerCase().includes(value?.toLowerCase()));
      setState((prevState) => ({
        ...prevState,
        filteredPlaylist: updatedList,
        searchValue: value,
      }));
      if (!value) setState((prevState) => ({ ...prevState, filteredPlaylist: [] }));
    } else {
      setState((prevState) => ({ ...prevState, inputValue: value }));
    }
  };

  const renderNotification = (msg) => {
    return displayNotification({
      title: msg
    });
  };

  const renderDeleteConfirmModal = () => {
    if (!showDeleteOverlay) return null;

    const closeModal = () => {
      setState((prevState) => ({ ...prevState, showDeleteOverlay: false }));
      if (closePopup) closePopup();
    };

    return (
      <div className="PP-DeleteModal">
        <div className="PPD-Inner">
          <span className="PPD-Close" onClick={() => closeModal()}>
            <i className="fa fa-times"></i>
          </span>
          <div className="PPD-Title">Delete playlist?</div>

          <div className="PPD-Content">
            <button className="PP-DeleteButton" onClick={() => onHandlePlaylist(PLAYLIST_ACTION.delete)}>
              Yes
              {eventLoading[PLAYLIST_ACTION.delete] && (
                <>
                  &nbsp;<i className="fa fa-spinner fa-spin"></i>
                </>
              )}
            </button>
            <button className="PP-DeleteButton Cancel" onClick={() => closeModal()}>
              No
            </button>
          </div>
        </div>
      </div>
    );
  };

  // buttons - create/update/delete playlist
  const onHandlePlaylist = (action) => {
    if ((action === PLAYLIST_ACTION.create || action === PLAYLIST_ACTION.update) && !inputValue?.trim()) return;

    if (allPlaylists.length > 9 && action === PLAYLIST_ACTION.create) {
      renderNotification('Maximum 10 playlists allowed!');
      setState((prevState) => ({ ...prevState, inputValue: '' }));
      return;
    }

    setState((prevState) => ({
      ...prevState,
      eventLoading: { ...prevState.eventLoading, [action]: true },
    }));

    let fn = createPlaylist;
    let params = { name: inputValue };

    if (action === PLAYLIST_ACTION.update) {
      fn = updatePlaylist;
      params = { id: selectedPlaylist.id, name: inputValue };
    } else if (action === PLAYLIST_ACTION.delete) {
      fn = deletePlaylist;
      params = { id: selectedPlaylist.id };
    }

    fn(params)
      .then(() => {
        renderNotification(`${action.charAt(0).toUpperCase() + action.slice(1)} playlist success!`);
      })
      .catch((err = {}) => {
        console.log(err);
        if (err.response?.status === 400) {
          renderNotification('Maximum 10 playlists allowed!');
        }
      })
      .finally(() => {
        setState((prevState) => ({
          ...prevState,
          eventLoading: { ...prevState.eventLoading, [action]: false },
        }));
        callback && callback({ name: inputValue, action }); // close popup from parent or refetch
      });
  };

  const renderList = () => {
    if (hideList) return null;

    let view = null;

    if (error) view = <div className="PP-Error">Something went wrong!</div>;

    if (playlistLoading) {
      view = [1, 2, 3, 4].map((item) => <ImageLoadingSkeleton key={item} className="PP-Loading" />);
    }

    if (allPlaylists.length !== 0) {
      const data = filteredPlaylist.length !== 0 ? filteredPlaylist : allPlaylists;

      view = (
        <>
          {renderTitleInput({
            title: 'Add to playlist',
            value: searchValue,
            action: 'search',
            showSearchIcon: 1,
            showSeeAll: 1,
          })}

          <ul className="PP-List">
            {data.map((item, index) => {
              const { id, name } = item || {};
              const hasMovieSceneInPlaylist = isVideoInPlaylist.includes(id);

              return (
                <li key={id + '-' + index}>
                  <span>{name}</span>
                  <button
                    type="button"
                    onClick={() => onAddRemoveVideoFromPlaylist(id)}
                    className={`${hasMovieSceneInPlaylist ? 'hasItem' : ''}`}
                  >
                    {hasMovieSceneInPlaylist ? 'Remove' : 'Add'}
                    {eventLoading[PLAYLIST_ACTION.add] === id && (
                      <>
                        &nbsp;<i className="fa fa-spinner fa-spin"></i>
                      </>
                    )}
                  </button>
                </li>
              );
            })}
          </ul>

          <div
            className="PP-Footer"
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                showAddNewPlaylist: !prevState.showAddNewPlaylist,
              }))
            }
          >
            <i className={`fa fa-${showAddNewPlaylist ? 'minus' : 'plus'}`}></i>
            Add to new playlist
          </div>
        </>
      );
    }

    return <div className="PP-ListContainer">{view}</div>;
  };

  const renderButton = (text, action, className) => {
    return (
      <button
        onClick={(e) => {
          e.stopPropagation();

          if (action === PLAYLIST_ACTION.delete) {
            setState((prevState) => ({
              ...prevState,
              showDeleteOverlay: true,
            }));
          } else {
            onHandlePlaylist(action);
          }
        }}
        className={className}
      >
        {text}
        {eventLoading[action] && (
          <>
            &nbsp;<i className="fa fa-spinner fa-spin"></i>
          </>
        )}
      </button>
    );
  };

  const renderButtons = (showSearchIcon) => {
    if (create && !showSearchIcon) {
      return <div className="PP-CreateContainer">{renderButton('create playlist', PLAYLIST_ACTION.create)}</div>;
    }

    if (update) {
      return (
        <div className="PP-UpdateContainer">
          {renderButton(PLAYLIST_ACTION.delete, PLAYLIST_ACTION.delete, 'DeleteBtn')}
          {renderButton(PLAYLIST_ACTION.update, PLAYLIST_ACTION.update)}
        </div>
      );
    }

    return null;
  };

  const renderTitleInput = ({ title, value, action, showSearchIcon, showSeeAll }) => {
    const showSeeAllLink = !hideList && showSeeAll && pathname !== `/${routes.userPlaylists}`;

    return (
      <div className="PP-Header">
        <div className="PPH-Inner">
          <span className="PP-Title">
            {title}
            {update && (
              <>
                <br />
                <span className="PP-Subtitle">Edit the name of your playlist</span>
              </>
            )}
          </span>

          {showSeeAllLink && <Link to={`/${routes.userPlaylists}`}>See all</Link>}
        </div>

        <div className="PP-InputContainer">
          <input
            value={value}
            onChange={(e) => onInputChange(e, action)}
            placeholder={create && !showSearchIcon ? 'Name Your Playlist' : ''}
          />
          {showSearchIcon && <i className="fa fa-search icon"></i>}
        </div>

        {renderButtons(showSearchIcon)}
      </div>
    );
  };

  const renderCreateUpdatePlaylists = () => {
    if (!showAddNewPlaylist && !hideList) return null;

    let title;
    if (create) title = 'Create new playlist';
    if (update) title = 'Update playlist';

    return renderTitleInput({ title, value: inputValue });
  };

  const renderClosePopupIcon = () => {
    if (playlistLoading) return null;

    return (
      <span
        className="PP-ClosePopupIcon"
        title="Close"
        onClick={() => {
          if (closePopup) closePopup();
        }}
      >
        <i className="fa fa-times"></i>
      </span>
    );
  };

  return (
    <div className="PP-Overlay" onClick={(e) => e.stopPropagation()}>
      {renderDeleteConfirmModal()}
      <div className="PlaylistsPlayerPopup" ref={ref}>
        {renderClosePopupIcon()}
        {renderList()}
        {renderCreateUpdatePlaylists()}
      </div>
    </div>
  );
});

PlaylistsPlayerPopup.displayName = 'PlaylistsPlayerPopup';

PlaylistsPlayerPopup.propTypes = {
  movieId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sceneId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hideList: PropTypes.bool,
  create: PropTypes.bool,
  update: PropTypes.bool,
  callback: PropTypes.func,
  closePopup: PropTypes.func,
  selectedPlaylist: PropTypes.object,
};

export default PlaylistsPlayerPopup;

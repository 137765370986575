import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { showThemeDetailsPage, showThemesPage } from '../../../../services/navigation/navigation.service';
import { useWLconfigContext } from '../../../../context/WLConfigContext';
import SlickSlider from '../../../../components/SlickSlider/SlickSlider';
import useBanners from '../../../../hook/useBanners';
import { ROUTES } from '../../../../services/navigation/navigation.routes';
import { isWeb } from '../../../../services/util-service/util.service';
import { useAuthContext } from '../../../../context/AuthContext';

import './JustAddedComponent.scss';

const JustAddedComponent = () => {
  const pathname = window.location.pathname;
  const { t } = useTranslation();
  const { userLoggedIn } = useAuthContext();
  const { wl_config: { categories, homepage_promo_banner } = {} } = useWLconfigContext();

  const isHomePage = pathname === ROUTES.root;
  const main = useBanners('main', 1);
  const promo = useBanners('promo', 1);

  const [state, setState] = useState({
    showBanner: false,
    showSlider: false
  });

  const {
    showBanner,
    showSlider
  } = state;

  useEffect(() => {
    if (([ROUTES.justAdded, ROUTES.mostWatched].includes(pathname)) && isWeb()) {
      setState(prevState => ({ ...prevState, showSlider: true, showBanner: true }));
    }

    if (isHomePage) {
      setState(prevState => ({
        ...prevState,
        showSlider: true,
        showBanner: homepage_promo_banner || userLoggedIn ? true : false
      }));
    }

    return () => {
      setState({
        showBanner: false,
        showSlider: false
      });
    }
  }, [pathname, isHomePage, homepage_promo_banner, userLoggedIn]);

  const renderFilters = () => {
    if (!userLoggedIn || !isHomePage || !isWeb()) return null;

    return (
      <div className="Filters">
        <div className="Label">{t('JustAddedComponent.showMe')}</div>

        <div className="Filters-inner">
          {categories?.map((item) => {
            const { id, name } = item;
            return (
              <span className="FilterItem" onClick={() => showThemeDetailsPage(id, name)} key={'theme-' + id}>
                {name}
              </span>
            );
          })}
        </div>

        <div className="SeeMore" onClick={showThemesPage}>
          {t('JustAddedComponent.seeMore')}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="JustAddedComponent">
        {showSlider && (
          <SlickSlider
            renderPreview
            slides={main}
            className='HomePageSlider SliderType'
            omitScreenWidth
          />
        )}
        {renderFilters()}
        {showBanner && <SlickSlider renderPreview slides={promo} />}
      </div>
    </>
  );
};

JustAddedComponent.displayName = 'JustAddedComponent';

export default JustAddedComponent;

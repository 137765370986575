import React from 'react';
import { isWeb } from '../../services/util-service/util.service';

import './MovieOverlay.scss';

export default function MovieOverlay(props) {
  const {
    topText,
    middleText,
    bottomText,
    btnText,
    btnColor,
    btnBgColor,
    btnPosition,
  } = props;

  const btnStyle = {};

  if (!isWeb()) {
    Object.assign(btnStyle, {
      color: btnColor,
      backgroundColor: btnBgColor
    })
  }

  const btnPositionClassName = () => {
    if (isWeb()) return '';

    // set in Gandolf
    const pos = {
      1: 'TopLeft',
      2: 'TopRight',
      3: 'BottomLeft',
      4: 'BottomRight',
      5: 'Center',
      default: 'Center'
    };

    return pos[btnPosition] || pos['default'];
  };

  return (
    <div className="MovieOverlay">
      <div className="TextWrapper">

        {isWeb() && topText && <div className="TopText">{topText}</div>}

        {isWeb() && middleText && (
          <div className="MiddleText">
            {middleText}
          </div>
        )}

        {isWeb() && bottomText && <div className="BottomText">{bottomText}</div>}

        {isWeb() ?
          <div className={`VideoBtn`}>Watch now</div> :
          btnText && <div className={`VideoBtn ${btnPositionClassName()}`} style={btnStyle}>{btnText}</div>
        }
      </div>
    </div>
  );
};
import React from 'react';
import './Arrow.scss';

const Arrow = ({ show }) => {
  const classes = ['Arrow'];
  if (show) {
    classes.push('Show');
  }
  return <span className={classes.join(' ')} />;
};

export default Arrow;

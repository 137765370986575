import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';
import AlphabeticNavigation from '../AlphabeticNavigation/AlphabeticNavigation';
import AlphabeticViewLoadingSkeleton from './AlphabeticViewLoadingSkeleton/AlphabeticViewLoadingSkeleton';
import { isWeb } from '../../services/util-service/util.service';
import BaseSectionHeader from '../BaseSectionHeader/BaseSectionHeader';
import './AlphabeticView.scss';

const sections = {
  sexActs: 'sexActs',
  themes: 'themes',
};

const AlphabeticView = (props) => {
  const { activeSection, data } = props;
  const { loaded } = data[activeSection];

  const { t } = useTranslation();
  const mainRef = useRef();
  const scrollElementRef = useRef();

  const itemsMap = (item, section) => {
    const { id, name, goTo, themesRoute, sexActsRoute } = parseItem(item);
    const tab = isWeb() ? activeSection : section;
    let url = tab === sections.themes ? themesRoute : sexActsRoute;

    return (
      <Link className="ItemText" to={url || goTo} key={id}>
        {name}
      </Link>
    );
  };

  const parseItem = (item) => props.parseItem(item);

  const mapAlphabet = (letter) => ({
    name: letter,
    value: letter === '#' ? 'misc' : letter,
  });

  const renderAlphabeticNavigation = () => {
    let view = null;
    if (props.renderAlphabeticNavigation) {
      view = props.renderAlphabeticNavigation();
    } else {
      const { activeSection, data } = props;
      const { alphabet } = data[activeSection];
      const overridingCharacters = alphabet.map(mapAlphabet);
      view = (
        <AlphabeticNavigation
          data={overridingCharacters}
          onLetterChange={scrollToLetter}
        />
      );
    }
    return view;
  };

  const renderItemLetter = ({ data: items, letter, section }) => {
    return [
      <div className="Letter" key={letter} id={letter}>
        {letter}
      </div>,
      ...items.map((item) => itemsMap(item, section)),
    ];
  };

  const renderItemsByLetter = (data, section) => {
    const letter = data[0].name[0];
    const isNotLetter = !!letter.match(/[^a-zA-Z]/g);
    return renderItemLetter({
      data,
      letter: isNotLetter ? '#' : letter,
      section,
    });
  };

  const renderLoadingSkeleton = () => <AlphabeticViewLoadingSkeleton />;

  const renderSectionTitle = (sectionName) => {
    const { translationKey, activeSection } = props;
    return <BaseSectionHeader primaryText={t(`${translationKey}.${sectionName || activeSection}`)} />;
  };

  const renderSectionGroups = (data, section) => {
    return (
      <div className="Group" key={`${data[0]}-${section}`}>
        {renderItemsByLetter(data[1], section)}
      </div>
    );
  };

  const renderMainView = () => {
    const { activeSection, data } = props;
    const { items } = data[activeSection];
    let renderItems = Object.entries(items);
    let view = (
      <>
        {!isWeb() && activeSection === 'studios' && renderSectionTitle()}
        {renderItems.map(renderSectionGroups)}
      </>
    );

    if (!isWeb() && activeSection !== 'studios') {
      const themes = Object.entries(data[sections.themes].items);
      const sexActs = Object.entries(data[sections.sexActs].items);

      view = (
        <>
          {renderSectionTitle(sections.themes)}
          {themes.map((item) => renderSectionGroups(item, sections.themes))}

          {renderSectionTitle(sections.sexActs)}
          {sexActs.map((item) => renderSectionGroups(item, sections.sexActs))}
        </>
      );
    }

    return (
      <>
        {isWeb() && renderSectionTitle()}
        {isWeb() && renderAlphabeticNavigation()}

        {loaded ? (
          <div
            className={['AlphabeticView-inner', props.activeSection === 'studios' ? 'Studio' : ''].join(' ')}
            ref={scrollElementRef}
          >
            {view}
          </div>
        ) : (
          renderLoadingSkeleton()
        )}
      </>
    );
  };

  const scrollToLetter = (letter) => {
    const letterEl = document.getElementById(letter === '#' ? '#' : letter);
    scrollElementRef.current.scrollTo(0, letterEl.offsetTop);
    if (isWeb()) {
      letterEl.scrollIntoView({
        block: 'center',
      });
    }
  };

  let classes = ['AlphabeticView'];
  if (props.additionalClasses) {
    classes = [...props.additionalClasses, ...classes];
  }
  if (!loaded) {
    classes.push('LoadInProgress');
  }

  return (
    <div className={classes.join(' ')} ref={mainRef}>
      {renderMainView()}
    </div>
  );
};

AlphabeticView.displayName = 'AlphabeticView';

AlphabeticView.propTypes = {
  data: PropTypes.object,
  additionalClasses: PropTypes.array,
  parseItem: PropTypes.func.isRequired,
  activeSection: PropTypes.string.isRequired,
  translationKey: PropTypes.string.isRequired,
};

export default AlphabeticView;

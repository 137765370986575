import { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { globalHistory } from '@reach/router';

import Arrow from '../Arrow/Arrow';
import { useAuthContext } from '../../context/AuthContext.js';

import './ModalContainer.scss';

let DISABLE_MULTIPLE_MODALS = false; // in case of multiple 'open modal' buttons

export default function ModalContainer(props) {
  const {
    children,
    className,
    buttonText = '',
    showArrow = false,
    showSignInModal,
    showOverlay = false
  } = props;
  const [state, setState] = useState({ openModal: false, showBgOverlay: showOverlay });
  const { openModal, showBgOverlay } = state;

  const modalContentRef = useRef();
  const { userLoggedIn } = useAuthContext();

  useEffect(() => {
    const closeModal = () => {
      DISABLE_MULTIPLE_MODALS = false;
      if (modalContentRef.current) {
        setState((prevState) => ({ ...prevState, openModal: false, showBgOverlay: false }));
      }
    };

    const onEscapePress = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    document.addEventListener('keydown', onEscapePress);

    // on route change close modal
    globalHistory.listen(() => {
      closeModal();
    });

    return () => {
      document.removeEventListener('keydown', onEscapePress);
    }
  }, [userLoggedIn]);

  // close modal outside component 
  useEffect(() => {
    if (showSignInModal) {
      DISABLE_MULTIPLE_MODALS = false;
      toggleModal();
    }
  }, [showSignInModal]);

  const toggleModal = () => {
    if (DISABLE_MULTIPLE_MODALS) return;
    setState((prevState) => ({ ...prevState, openModal: true }));

    // prevents multiple btns to open the same modal
    let backup_counter = 0;
    const intervalId = setInterval(() => {
      // if content is found, else backup to end the interval
      if (modalContentRef.current || backup_counter >= 5) {
        DISABLE_MULTIPLE_MODALS = true;
        clearInterval(intervalId);
      }
      backup_counter++;
    }, 500);
  };

  const modalPortalRoot = document.getElementById('ModalPortalRoot');

  return (
    <div className='ModalContainer'>
      {buttonText && (
        <span className='ModalOpenBtn d-flex align-center' onClick={toggleModal}>
          {buttonText}
          {showArrow && <Arrow />}
        </span>
      )}

      {modalPortalRoot && openModal && (
        <>
          {createPortal(
            <div className={`ModalPortalContent ${className ? className : ''}`} ref={modalContentRef}>
              <div className='ModalPortalContent-Inner'>
                <span className='ModalCloseBtn' onClick={() => {
                  setState((prevState) => ({ ...prevState, openModal: false, showBgOverlay: false }));
                  DISABLE_MULTIPLE_MODALS = false;
                }}>
                  <i className='far fa-times-circle'></i>
                </span>
                {children}
              </div>
            </div>,
            modalPortalRoot
          )}
        </>
      )}
      {showBgOverlay && <div className='ModalOverlay'></div>}
    </div>
  );
} 
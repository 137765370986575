import { useState, useEffect } from 'react';

import {
  sendChatBotMessage,
  getChatBots,
  getChatBotHistory,
  sendChatFeedback
} from '../../services/aiChat-service/aiChat-service';
import { useAuthContext } from '../../context/AuthContext';
import { getUserLocalData } from '../../services/users-service/users.service';

import ChatList from './ChatList/ChatList';
import ChatMessage from './ChatMessage/ChatMessage';

import './AiChat.scss';

export default function AiChat() {
  const { userLoggedIn } = useAuthContext();
  const { custom7 } = getUserLocalData();

  const [state, setState] = useState({
    isPopupOpen: false,
    bots: [], // all chatbots
    messages: [], // between bot and user
    selectedBotId: null,
    inputValue: '',
    feedbackFromUser: { send: false, loading: false, success: false },
    loading: false,
    error: false
  });

  const {
    isPopupOpen,
    bots,
    messages,
    selectedBotId,
    inputValue,
    feedbackFromUser,
    loading,
    error
  } = state;

  useEffect(() => {
    if (!isPopupOpen) return;

    getChatBots()
      .then(resp => {
        const data = resp?.data?.data || [];
        setState(prev => ({ ...prev, bots: data, loading: false }));
      })
      .catch(err => {
        console.log(err);
        setState(prev => ({ ...prev, loading: false, error: true }));
      })

    return () => {
      setState(prev => ({
        ...prev,
        bots: [],
        messages: [],
        selectedBotId: null,
        inputValue: '',
        feedbackFromUser: { send: false, loading: false, success: false },
        loading: false,
        error: false
      }));
    }
  }, [isPopupOpen]);

  const onListItemClick = (id) => {
    // for Go Back button
    if (!id) {
      setState(prev => ({
        ...prev,
        inputValue: '',
        selectedBotId: null,
        messages: [],
        feedbackFromUser: { send: false, loading: false, success: false }
      }));
      return;
    }

    setState(prev => ({ ...prev, inputValue: '', loading: true }));

    getChatBotHistory(id)
      .then(resp => {
        const data = resp?.data?.data?.messages || [];
        setState(prev => ({ ...prev, selectedBotId: id, messages: data, loading: false }));
      })
      .catch(err => {
        console.log(err);
        setState(prev => ({ ...prev, loading: false }));
      })
  };

  // open/close chat popup
  const onBubbleIconClick = () => {
    if (loading) return;
    setState(prev => ({ ...prev, isPopupOpen: !prev.isPopupOpen, loading: true }));
  };

  const onInputChange = (val) => setState(prev => ({ ...prev, inputValue: val }));

  const getSelectedBot = () => {
    const selected = bots.find(item => item?.id === selectedBotId);
    return selected || {};
  };

  const onSendMessage = () => {
    if (feedbackFromUser.send) {
      setState(prev => ({
        ...prev,
        inputValue: '',
        feedbackFromUser: { ...prev.feedbackFromUser, loading: true }
      }));

      sendChatFeedback(selectedBotId, inputValue)
        .then(resp => {
          if (resp.status === 200) {
            setState(prev => ({
              ...prev,
              feedbackFromUser: { ...prev.feedbackFromUser, loading: false, success: true }
            }));
          }
        })
        .catch(err => {
          console.log(err);
        })
      return;
    }

    const userMsg = { role: 'user', content: inputValue };
    setState(prev => ({ ...prev, inputValue: '', messages: [...prev.messages, userMsg] }));

    sendChatBotMessage(selectedBotId, inputValue)
      .then(resp => {
        const botMsg = resp?.data?.data || [];
        setState(prev => ({ ...prev, messages: [...prev.messages, botMsg] }));
      })
      .catch(err => {
        console.log(err);
      })
  };

  const onFeedbackBtnClick = () => {
    setState(prev => ({ ...prev, inputValue: '', feedbackFromUser: { ...prev.feedbackFromUser, send: true } }));
  };

  const renderView = () => {
    if (!isPopupOpen) return null;

    let view;

    if (loading) {
      view = (
        <div className='d-flex align-center justify-center AiChatLoading'>
          <i className='fa fa-spinner fa-spin'></i>
        </div>
      )
    } else if (error) {
      view = <div className='d-flex align-center justify-center AiChatErrorText'>No available chatbots!</div>
    } else if (selectedBotId) {
      view = (
        <ChatMessage
          inputValue={inputValue}
          onInputChange={onInputChange}
          goBackToList={onListItemClick}
          selectedBot={getSelectedBot()}
          onSendMessage={onSendMessage}
          messages={messages}
          feedback={feedbackFromUser}
          handleFeedback={onFeedbackBtnClick}
        />
      )
    } else {
      view = <ChatList items={bots} onListItemClick={onListItemClick} />
    }

    return <div className='AiChatContent'>{view}</div>
  };

  if (!userLoggedIn || !custom7) return null;

  return (
    <div className='AiChat'>
      <div className='BubbleIcon'>
        <span onClick={() => onBubbleIconClick()} className='cursor-pointer'>
          {isPopupOpen ? <i className='fas fa-times-circle' title='Close Chat'></i> : <i className='fas fa-comments'></i>}
        </span>
      </div>
      {renderView()}
    </div>
  )
};
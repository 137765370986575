import React from 'react';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';

import { isWeb } from '../../../../services/util-service/util.service';
import MemberPWReminder from '../../../../components/MemberPWReminder/MemberPWReminder.js';
import ModalContainer from '../../../../components/ModalContainer/ModalContainer.js';

import './MyAccountUserInfos.scss';

export default function MyAccountUserInfos(props) {
  const { email } = props;
  const { t } = useTranslation();

  return (
    <div className="MyAccountUserInfos d-flex align-center">
      <div className="UserEmail">{email}</div>
      {isWeb() && <div className="Separator" />}
      <div className="d-flex">
        <ModalContainer buttonText={t('MyAccountUserInfos.resetPassword')}>
          <MemberPWReminder />
        </ModalContainer>
        <div className="Separator" />
        <Link to="/help" className="UserAccountLink">
          {t('MyAccountUserInfos.customerService')}
        </Link>
      </div>
    </div >
  );
} 
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { backendParameters } from '../../constants/navigation';

import './ShowMeSortByNavigation.scss';

export default function ShowMeSortByNavigation(props) {
  const {
    onShowMeClick,
    onSortByClick,
    showMeItems,
    sortByItems,
    showMeInitialValue = '',
    sortByInitialValue = '',
    hideSeparators
  } = props;
  const { t } = useTranslation();

  const [state, setState] = useState({ showMe: '', sortBy: '' });

  const { sortBy, showMe } = state;

  useEffect(() => {
    setState({ showMe: showMeInitialValue, sortBy: sortByInitialValue });
  }, [showMeInitialValue, sortByInitialValue]);

  const handleItemClick = (e) => {
    try {
      const clickedFilter = e.target.getAttribute('data-item-type');
      const videoType = backendParameters.get('videoType');
      const videoSortBy = backendParameters.get('videoSortBy');
      let updateParentStateFn = onShowMeClick; // for stars page
      let stateKey;

      if (videoType.includes(clickedFilter)) {
        updateParentStateFn = onShowMeClick;
        stateKey = 'showMe';
      } else if (videoSortBy.includes(clickedFilter)) {
        updateParentStateFn = onSortByClick;
        stateKey = 'sortBy';
      }

      if (stateKey) {
        setState((prevState) => ({ ...prevState, [stateKey]: clickedFilter }));
      }
      if (updateParentStateFn) {
        updateParentStateFn(clickedFilter);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderItems = (label, items = []) => {
    if (!Array.isArray(items)) return null;

    return (
      <div className='Section'>
        <div className='Label'>{label}</div>

        {items.map((item, index) => {
          const separator = index !== items.length - 1;
          const { name, value } = item || {};
          const selected = value === sortBy || value === showMe;

          return (
            <React.Fragment key={name}>
              <div
                className={`Item ${selected ? 'Selected' : ''} ${hideSeparators ? 'NoSeparator' : ''}`}
                data-item-type={value}
                onClick={(e) => {
                  if (selected) return;
                  handleItemClick(e);
                }}
              >{name}</div>
              {separator && !hideSeparators && <div className='Separator' />}
            </React.Fragment>
          )
        })}
      </div>
    )
  };

  return (
    <div className='ShowMeSortByNavigation'>
      {showMeItems && renderItems(t('ShowMeSortByNavigation.showMe'), showMeItems)}
      {sortByItems && renderItems(t('ShowMeSortByNavigation.sortBy'), sortByItems)}
    </div>
  );
}

ShowMeSortByNavigation.propTypes = {
  hideSeparators: PropTypes.bool,
  onShowMeClick: PropTypes.func,
  onSortByClick: PropTypes.func,
  showMeInitialValue: PropTypes.string,
  sortByInitialValue: PropTypes.string,
  showMeItems: PropTypes.array,
  sortByItems: PropTypes.array,
}; 
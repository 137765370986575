import React, { useEffect, useState, useRef } from 'react';

import MoviesScenesGrid from '../../../components/MoviesScenesGrid/MoviesScenesGrid';
import PageIndicator from '../../../components/PageIndicator/PageIndicator';
import BoxCoverPlayerDetailsComponent from '../../../components/BoxCoverPlayerDetailsComponent/BoxCoverPlayerDetailsComponent';
import BannerSetViewFunc from '../../../components/BannerSetView/BannerSetViewFunc';
import BaseSectionHeader from '../../../components/BaseSectionHeader/BaseSectionHeader';
import SlickSlider from '../../../components/SlickSlider/SlickSlider';

import { getMovies } from '../../../services/movies/movies.service';
import { isWeb } from '../../../services/util-service/util.service';
import { navigationOptions } from '../../../constants/navigation';
import useBanners from '../../../hook/useBanners';
import useMoviesScenesData from '../../../hook/useMoviesScenesData';

import './OriginalsPage.scss';

const OriginalsPage = (props) => {
  const { location = {} } = props;
  const originalsMiddle = useBanners('originalsMiddle', 1);

  const {
    videos,
    pagination: { page, last_page },
    sort,
    type,
    combo,
    loading,
    error,
    onPageChange,
    onTypeChange,
    onSortByClick,
  } = useMoviesScenesData({
    location,
    hookParams: { per_page: 24, originals: 1 },
  });

  const [state, setState] = useState({
    firstMovieInTheListId: '',
    spotlightId: '',
  });
  const { spotlightId } = state;
  const firstMovieInTheListId = videos[0]?.movie?.id || ''; // get from useMoviesScenesData() scenes
  const firstMountRef = useRef(true);

  useEffect(() => {
    if (isWeb()) {
      if (firstMountRef.current) {
        getMovies({ page: 1, sort_by: 'newest', spotlight: 1 })
          .then((resp = {}) => {
            const spotlightMovieId = resp.data?.data?.movies?.[0]?.movieId || '';
            setState((prevState) => ({
              ...prevState,
              spotlightId: spotlightMovieId,
            }));
            firstMountRef.current = false;
          })
          .catch((err) => console.log(err));
      }
      if (type === navigationOptions.scenes) {
        setState((prevState) => ({ ...prevState, firstMovieInTheListId }));
      }
    }
  }, [loading, firstMovieInTheListId, type]);

  const renderPagination = () => {
    if (page === 1 && last_page === 1) return null;
    if (error) return null;
    return (
      <PageIndicator
        page={page}
        pageCount={last_page}
        onPageChange={onPageChange}
        scrollIntoView=".ShowMeSortByNavigation"
      />
    );
  };

  const numOfVideos = (param) => {
    const half = Math.ceil(videos.length / 2);
    const firstHalf = videos.slice(0, half);
    const secondHalf = videos.slice(half);

    if (param) {
      return firstHalf;
    } else {
      return secondHalf;
    }
  };

  const renderView = () => {
    return (
      <>
        <BannerSetViewFunc bannerName="defaultOriginals" />
        <BannerSetViewFunc bannerName="promo" />

        {/* first movie in the list */}
        {isWeb() && type === navigationOptions.scenes && firstMovieInTheListId && (
          <BoxCoverPlayerDetailsComponent movieId={firstMovieInTheListId} />
        )}

        <BaseSectionHeader primaryText={'New from NakedSword'} />

        {/* spotlight movie */}
        {isWeb() && spotlightId && <BoxCoverPlayerDetailsComponent movieId={spotlightId} />}

        <MoviesScenesGrid
          data={numOfVideos(1)} // first half
          loading={loading}
          type={type}
          sort={sort}
          onShowMeClick={onTypeChange}
          onSortByClick={onSortByClick}
          error={error}
          combo={combo}
        />

        <SlickSlider slides={originalsMiddle} showDots hideArrows className="OriginalsSlider" />

        <MoviesScenesGrid
          data={numOfVideos()} // second half
          loading={loading}
          type={type}
          sort={sort}
          onShowMeClick={onTypeChange}
          onSortByClick={onSortByClick}
          error={error}
          hideNavigation
          combo={combo}
        />

        {renderPagination()}
        <BannerSetViewFunc bannerName="tour" />
      </>
    );
  };

  return <div className="OriginalsPage">{renderView()}</div>;
};

OriginalsPage.displayName = 'OriginalsPage';

export default OriginalsPage;

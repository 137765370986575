import axios from 'axios';

const urls = {
  chatBotsList: '/frontend/chatbots',
  chatBotHistory: '/frontend/chatbot',
  chatBotMessage: '/frontend/chatbot',
  chatFeedback: '/frontend/chatbot'
};

export const getChatBots = () => axios.get(urls.chatBotsList);
export const getChatBotHistory = (id) => axios.get(`${urls.chatBotHistory}/${id}/history`);
export const sendChatBotMessage = (id, message) => {
  return axios.post(`${urls.chatBotMessage}/${id}/message`, { message });
};
export const sendChatFeedback = (id, feedback) => {
  return axios.post(`${urls.chatFeedback}/${id}/feedback`, { feedback });
};
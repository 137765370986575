import React from 'react';

import SinglePlayer from '../../../../components/SinglePlayer/SinglePlayer';

export default function FreeRideOrDieBonusBehindScenes() {
  return (
    <>
      <SinglePlayer
        src='https://d34kds0xkdmtq8.cloudfront.net/RSS190_SK.mp4'
        poster='https://spotlight.nakedcdn.com/nakedsword/img/free/rideordie/videoposterimage-haditcoming.jpg'
      />

      <h4 style={{
        fontSize: '120%',
        margin: '1em 0'
      }}>
        RIDE OR DIE: BEHIND BARS :: HE HAD IT COMING
      </h4>

      <p>
        Join the cast of RIDE OR DIE as they take a break from shooting the intense action-drama to perform their
        unique rendition of 'Cell Block Tango' from 'Chicago'.
      </p>
    </>
  )
};
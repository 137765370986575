import { useEffect } from 'react';
import ReactGA from 'react-ga4'; // https://www.npmjs.com/package/react-ga4
import TagManager from 'react-gtm-module'; // https://www.npmjs.com/package/react-gtm-module

import { getCookie } from '../services/util-service/util.service';
import { getNatsData } from '../services/nats-service/nats.service';

export const useGAanalytics = ({ loading, loggedIn, GA_id, GTM_id, GA_uni_id }) => {
  useEffect(() => {
    if (loading) return;

    const decodeNatsSMP = () => {
      const { natsCode } = getNatsData();

      if (!natsCode) {
        console.log('NATS code not found on initial load.');
        return null;
      }

      let decodedData = null;
      try {
        decodedData = atob(natsCode);
      } catch (error) {
        console.error('Error decoding NATS data in index.html: ', error);
      }

      if (!decodedData || typeof decodedData !== 'string') return null;

      const splitData = decodedData.split('.');
      const labels = [
        'AffiliateID',
        'ProgramID',
        'SiteID',
        'TourID',
        'CampaignID',
        'AdtoolID',
        'AdToolSub1ID',
        'AdToolSub2ID',
      ];

      let nats = {};
      splitData?.forEach((value, index) => {
        if (index < labels.length) {
          nats[labels[index]] = value;
        }
      });

      return { nats };
    };

    // Google Analytics
    if (GA_id) {
      const GA_ids = [{ trackingId: GA_id }];

      if (GA_uni_id) {
        GA_ids.push({ trackingId: GA_uni_id });
      }

      ReactGA.initialize(GA_ids);
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname
      });
    }
    // Google Analytics End

    // Google Tag Manager
    if (GTM_id) {
      const tagManagerArgs_1 = {
        gtmId: GTM_id,
        dataLayer: {
          userType: loggedIn ? 'member' : 'nonmemeber',
          userStatus: loggedIn ? 'loggedin' : 'loggedout',
          hostname: window.location.hostname,
          logged: loggedIn ? 'Yes' : "No"
        }
      };

      const tagManagerArgs_2 = {
        gtmId: GTM_id,
        dataLayer: {
          event: 'nats',
          sendTo: GA_id,
          hostname: window.location.hostname,
          ...decodeNatsSMP()
        }
      };

      TagManager.initialize(tagManagerArgs_1);
      TagManager.initialize(tagManagerArgs_2);
    }
    // Google Tag Manager End

  }, [loading, loggedIn, GA_id, GTM_id, GA_uni_id]);
};

const getOrCreateUserId = () => {
  const storedUserId = getCookie('user_id');

  if (storedUserId) {
    return storedUserId;
  }

  try {
    const userId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });

    document.cookie =
      `user_id=${userId};path=/;domain=` + window.baseDomain + `;max-age=31536000;secure;SameSite=Lax;`;

    return userId;

  } catch (error) {
    console.error('Error generating or retrieving user_id: ', error);
  }
};

const gtagFunc = (data) => {
  const userId = getOrCreateUserId();
  const { event, ...rest } = data || {};
  rest.userId = userId;
  ReactGA.event(event, { ...rest });
};

// function for onLoad
export const useGtag = ({ data, skipTracking }) => {
  // path triggers rerender
  useEffect(() => {
    if (skipTracking) return;
    gtagFunc(data);
    // eslint-disable-next-line
  }, [window.location.pathname]);
};

// function for events, button click...
export const onEventGtag = (data) => {
  gtagFunc(data);
};

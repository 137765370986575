import { navigate } from '@reach/router';
import {
  getThemeDetailsRoute,
  getMovieDetailsPageRoute,
  getSceneDetailsPageRoute,
  getStreamBlockedRoute,
} from './navigation.service.routes';
import { ROUTES } from './navigation.routes';

export const showCompliancePage = () => navigate(`/${ROUTES.compliance}`);

export const showMovieDetailsPage = (movieId, movieName) => navigate(getMovieDetailsPageRoute(movieId, movieName));

export const showSceneDetailsPage = (movieId, movieName, sceneId) =>
  navigate(getSceneDetailsPageRoute(movieId, movieName, sceneId));

export const showApplicationStart = () => navigate(ROUTES.root);

export const showThemesPage = () => navigate(`/${ROUTES.themes}`);

export const showThemeDetailsPage = (id, name) => navigate(getThemeDetailsRoute(id, name));

export const showMyAccountPage = () => navigate(`/${ROUTES.myAccount}`);

export const showHelpPage = () => navigate(`/${ROUTES.help}`);

export const showTermsOfUsePage = () => navigate(`/${ROUTES.termsOfUse}`);

export const showPrivacyPolicyPage = () => navigate(`/${ROUTES.privacyPolicy}`);

export const showMemberDeals = () => navigate(`/${ROUTES.memberDeals}`);

export const showStreamBlockedPage = () => navigate(getStreamBlockedRoute());

export const showJoinNowPage = () => navigate('/join');

export const showStarLetterListPage = (letter) => navigate(`${ROUTES.stars}/index/${letter}`);

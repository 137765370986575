import React from 'react';

import './MyAccountSectionHeader.scss';

export default function MyAccountSectionHeader(props) {
  const { actions = [], title = '' } = props;

  const renderAction = ({ text, onClick }, index) => {
    return (
      <div
        key={index}
        className="Action"
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        <span className="ActionText">{text}</span>
      </div>
    );
  };

  return (
    <div className="MyAccountSectionHeader">
      <div className="Title">{title}</div>
      {actions.map(renderAction)}
    </div>
  );
}